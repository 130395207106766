var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"root"},[_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"lg","opacity":"0.6"}},[_c('v-card',{staticClass:"text-center mt-10",attrs:{"flat":"","shaped":""}},[_c('v-card-title',[(this.$route.params.id > 0)?_c('b-button',{staticClass:"mx-3 mt-0 geri",attrs:{"size":"sm","id":"geri","variant":"outline-primary"},on:{"click":function($event){return _vm.goBack()}}},[_c('b-icon',{attrs:{"icon":"arrow90deg-left"}})],1):_vm._e(),_c('h3',{staticClass:"mt-1"},[_vm._v(_vm._s(_vm.title ? _vm.title : 'Sınav Oturumları'))])],1),_c('v-card-text',[(_vm.examSessions.length > 0)?_c('div',{staticClass:"pt-5"},[_vm._l((_vm.examSessions.slice(0, _vm.count)),function(item){return _c('div',{key:item.Id,staticClass:"d-flex align-items-center mb-8"},[_c('div',{staticClass:"flex-grow-1 text-left"},[_c('a',{on:{"click":function($event){_vm.ExamSessionId = item.Id
                  _vm.StartDate = item.StartDate
                  _vm.EndDate = item.EndDate
                  _vm.getExamSessionStudentFromApi()}}},[_c('a',{staticClass:"text-gray-800 text-hover-primary fw-bolder fs-6 mt-1",attrs:{"href":"#"}},[_vm._v(_vm._s(item.Name)+" "),_c('br'),_vm._v(" Süre: "+_vm._s(item.Duration)+" dk "),_c('br')])]),_c('div',{staticClass:"text-gray-800 text-hover-primary fw-bolder fs-6 mt-1"},[_vm._v(" Sınav Başlangıç: "+_vm._s(_vm._f("moment")(item.StartDate))+" ")]),_c('div',{staticClass:"text-gray-800 text-hover-primary fw-bolder fs-6 mt-1"},[_vm._v(" Sınav Bitiş: "+_vm._s(_vm._f("moment")(item.EndDate))+" ")]),_c('hr')]),_c('a',{on:{"click":function($event){_vm.ExamSessionId = item.Id
                _vm.StartDate = item.StartDate
                _vm.EndDate = item.EndDate
                _vm.getExamSessionStudentFromApi()}}},[_c('a',{staticClass:"btn btn-icon btn-light btn-hover-primary btn-sm"},[_c('span',{staticClass:"svg-icon svg-icon-md svg-icon-success"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/Navigation/Arrow-right.svg"}})],1)])])])}),_c('v-dialog',{attrs:{"max-width":"350px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title'),_c('v-card-text',[_c('v-container',{staticClass:"text-center text-break",attrs:{"fluid":""}},[(_vm.examSessionStudentId)?_c('h2',[_vm._v("BU SINAVDA KAYDINIZ BULUNMAKTADIR")]):_c('h2',[_vm._v("OTURUMA GİRDİĞİNİZDE SÜRENİZ BAŞLAYACAKTIR")]),_c('v-switch',{attrs:{"hide-details":"","label":"Online Kitapçık"},model:{value:(_vm.IsDigital),callback:function ($$v) {_vm.IsDigital=$$v},expression:"IsDigital"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" VAZGEÇ ")]),(_vm.examSessionStudentId)?_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.go()}}},[_vm._v(" DEVAM ET ")]):_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.go()}}},[_vm._v(" BAŞLAT ")]),_c('v-spacer')],1)],1)],1),(_vm.examSessions.length > _vm.count)?_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){_vm.count += _vm.count}}},[_vm._v("Devamını Yükle")]):_vm._e()],2):_c('div',{staticClass:"pt-5"},[_c('v-alert',{attrs:{"type":"warning"}},[_vm._v(" Katılabileceğiniz oturum bulunamadı ")])],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }